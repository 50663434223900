import Creepify from 'lunicode-creepify'

Creepify.options.maxHeight = 1

const TextGlitch = (text, creepyIndex, decode) => {
  Creepify.options.maxHeight = creepyIndex

  if( decode ) {
    return(
      Creepify.decode(text)
    )
  } else {
    return (
      Creepify.encode(text)
    )
  }
}

export default TextGlitch
