import React from "react"

import Container from "../components/questions-flow/container"

import QuestionsData from "../data/questions.json"
import Questions from "../components/questions-flow/"

const IndexPage = ( ) => (
  <React.Fragment>
    <Container data={QuestionsData}>
      <Questions data={QuestionsData}/>
    </Container>
  </React.Fragment>
)

export default IndexPage
