import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import { rem } from 'polished'

const AnswerEl = styled.button.attrs({className: 'answer-button'})`
  background: #FAFAFA;
  border: 1px solid #E6E6E6;
  font-family: ${props => props.theme.ffTertiary};
  letter-spacing: ${rem(-0.04)};
  text-align: center;
  line-height: 1.5;
  width: 100%;
  padding: ${rem(18)};
  user-select: none;
  overflow: hidden;
  position: relative;

  @media ${props => props.theme.smallUp} {
    font-size: ${rem(18)};
  }

  @media ${props => props.theme.smallDown} {
    font-size: ${rem(16)};
  } 

  .text-wrapper {
    display: block;
  }

  .alternative-text {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ${rem(18)};
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: ${props => props.theme.colorGreen};
    color: #fff;
  }

  &:not(:last-of-type) {
    border-bottom: 0;
  }

  &:first-of-type {
    border-radius: ${rem(10)} ${rem(10)} 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 ${rem(10)} ${rem(10)};
  }
`


class Answer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <AnswerEl onClick={()=> { this.props.onClick() }}>
        <span className="text-wrapper">{this.props.text}</span>
        {this.props.altText && <span className="alternative-text">{this.props.altText}</span>}
      </AnswerEl>
    )
  }
}

Answer.propTypes = {
  text: PropTypes.string,
  altText: PropTypes.string,
  onClick: PropTypes.any
}

export default Answer
