import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import { rem } from 'polished'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Answer from './answer'

const QuestionEl = styled.h2`
  text-align: center;
  margin: 0;
  letter-spacing: ${rem(-0.05)};
  line-height: 1.5;
  font-weight: 400;

  @media ${props => props.theme.smallUp} {
    font-size: ${rem(24)};
    margin-bottom: ${rem(40)};
  }

  @media ${props => props.theme.smallDown} {
    font-size: ${rem(20)};
    margin-bottom: ${rem(30)};
  } 
`

const HeadingPrefix = styled.div`
  color: ${props => props.theme.colorBlue};
  text-transform: uppercase;
  text-align: center;
  font-family: ${props => props.theme.ffTertiary};
  font-weight: 300;
  font-size: ${rem(14)};
  color: #42B6DF;
  letter-spacing: ${rem(1)};
  line-height: 1.4;
  padding-bottom: ${rem(10)};
`

const Status = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: ${props => props.theme.colorBlue};
    width: ${props => props.progress}%;
    height: 100%;
    transition: 0.5s;
  }
`

const QuestionContainer = styled.div`
  position: relative;
`

class Question extends React.Component {
  constructor(props) {
    super(props)
  }

  variantClicked = () => {
    this.props.questionChange()
  }

  render() {

    let progress = ((this.props.currentQuestion+1) / (this.props.totalQuestions+1)) *100

    return (
      <React.Fragment>
        <Status progress={progress} />

        <HeadingPrefix>Question #{this.props.currentQuestion+1}</HeadingPrefix>

        <QuestionContainer>

        <TransitionGroup className={`question-${this.props.currentQuestion+1}`}>
          <CSSTransition
            key={this.props.currentQuestion}
            timeout={300}
            classNames="fade"
          >
            <div>
              <QuestionEl data-text={this.props.question}>{this.props.question}</QuestionEl>

              {this.props.answers.map( (item, index) => (
                <Answer
                  key={index}
                  onClick={this.variantClicked}
                  text={item.text}
                  altText={item.altText}
                />
              ))}
            </div>
          </CSSTransition>
        </TransitionGroup>

        </QuestionContainer>

      </React.Fragment>
    )
  }
}

Question.propTypes = {
  question: PropTypes.string,
  answers: PropTypes.array,
  questionChange: PropTypes.any,
  currentQuestion: PropTypes.any,
  totalQuestions: PropTypes.any
}

export default Question
