import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'

const Button = styled.button.attrs({className:'intro-button'})`
  background: #42b6df;
  color: #fff;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: ${rem(60)};
  font-size: ${rem(18)};
  font-weight: 700;
  border-radius: 999em;
  text-align: center;

  @media ${props => props.theme.smallUp} {
    padding-left: ${rem(80)};
    padding-right: ${rem(80)};
    margin-top: ${rem(50)};    
  }

  @media ${props => props.theme.smallDown} {
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
    margin-top: ${rem(30)}; 
  }    
`

const TextContainer = styled.div.attrs({className:'intro-text-container'})`
  color: ${props => props.theme.colorBlack};
  letter-spacing: ${rem(-0.04)};
  text-align: justify;
  line-height: 1.7;

  @media ${props => props.theme.smallUp} {
    font-size: ${rem(18)};
  }

  @media ${props => props.theme.smallDown} {
    font-size: ${rem(16)};
  }    

  p:not(:last-of-type) {
    @media ${props => props.theme.smallUp} {
      margin-bottom: ${rem(35)};
    }

    @media ${props => props.theme.smallDown} {
      margin-bottom: ${rem(15)};
    }      
  }

  .highlight {
    color: ${props => props.theme.colorRed};
  }
`

const Tag = styled.span`
  display: inline-block;
  margin-left: ${rem(10)};
  border: 1px solid #42B6DF;
  border-radius: 4px;
  font-family: ${props => props.theme.ffTertiary};
  font-size: 16px;
  color: #42B6DF;
  letter-spacing: ${rem(-0.03)};
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};
  margin-right: ${rem(5)};
`

const Notice = styled.span`
  position: fixed;
  bottom: 0;
  left: 100%;
  width: 100%;
  transform-origin: left bottom;
  white-space: nowrap;
  transform: rotate(-90deg);
  font-size: ${rem(10)};
  letter-spacing: ${rem(-0.04)};
  font-family: ${props => props.theme.ffTertiary};
  color: rgba(8, 27, 51, 0.3);

  .highlight {
    color: ${props => props.theme.colorRed};
  }

  .inner-content {
    /* opacity: 0; */
    transition: 0.5s;
  }

  @media ${props => props.theme.smallUp} {
    padding-left: ${rem(60)};
    padding-bottom: ${rem(20)};
  }

  @media ${props => props.theme.smallDown} {
    padding-left: ${rem(40)};
    padding-bottom: ${rem(3)};
  }    
`

const Intro = props => (
  <React.Fragment>
    <TextContainer>
      <p>Hello employee <Tag>#6255486</Tag>,</p>
      <p>Welcome to the Mosaic Corp survey! Your opinion is very important to us. The following series of questions will help us better understand your experience here at Mosaic Corp. Your responses will remain anonymous<span className="highlight">*</span> so please answer as honestly as possible.</p>
      <p>Thank you in advance for filling this out during your unpaid break time or lunch hour, your compliance has been noted.</p>
    </TextContainer>

    <Button onClick={props.surveyStart}>start survey</Button>

    <Notice><div className="notice-inner-content"><span className="highlight">*</span> Mosaic Corp has the right to share your responses with HR if they seem detrimental.</div></Notice>
  </React.Fragment>
)

Intro.propTypes = {
  surveyStart: PropTypes.any,
}

export default Intro
