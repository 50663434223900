import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { rem } from "polished"

import { StoreConsumer } from '../../store'

import FooterText from './footer-text';

const FooterEl = styled.footer`
  position: relative;
  border-top: 1px solid #e6e6e6;
  background: ${props => props.theme.colorGrey};
  border-radius: 0 0 ${rem(10)} ${rem(10)};
  display: flex;
  justify-content: space-between;
  font-family: ${props => props.theme.ffTertiary};
  font-size: ${rem(12)};
  color: #c0c0c0;
  letter-spacing: ${rem(-0.03)};

  @media ${props => props.theme.smallUp} {
    padding: ${rem(35)};
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(30)} ${rem(20)};
  }    
`

const Date = styled.span`
`

class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minutes: 0,
    }

    if(typeof window != 'undefined') {
      this.setDate()
    }
  }

  day; daySuffix; month; date; minutes;

  setDate = () => {
    this.date = new window.Date()
    this.day = this.date.getDate()
    this.daySuffix = 'th';

    if( this.day % 20 == 1 ) {
      this.daySuffix = 'st'
    } else if( this.day % 20 == 2 ) {
      this.daySuffix = 'nd'
    } else if( this.day % 20 == 3 ) {
      this.daySuffix = 'rd'
    }

    this.month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][this.date.getMonth()]

    this.minutes = this.date.getMinutes()

    window.setInterval( () => {
      let minutes = new window.Date().getMinutes()

      if( minutes > this.state.minutes ) {
        this.setState({
          minutes: minutes
        })
      }
    }, 1000)
  }

  render() {

    return (

      <StoreConsumer>
        {({ currentQuestion }) => (
          <FooterEl>
            <FooterText currentQuestion={currentQuestion} data={this.props.data} />

            { typeof window != 'undefined' && (
              <Date>{this.date.getUTCFullYear()} {this.month} {this.day}{this.daySuffix} {this.date.getUTCFullYear()} {this.date.getHours()}:{/^\d$/.test(this.state.minutes) && 0}{ this.state.minutes }</Date>
            )
            }
          </FooterEl>
        )}
      </StoreConsumer>
    )
  }
}

Footer.propTypes = {
  data: PropTypes.any
}

export default Footer
