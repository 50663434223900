import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Link from "../link"

import SvgLogo from "../../assets/images/logo.inline.svg"
import HeaderText from "../../assets/images/intro-text.inline.svg"

const HeaderEl = styled.header.attrs({className: 'header'})`
  position: relative;
  background: #000;
  border-radius: ${rem(10)} ${rem(10)} 0 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #E6E6E6;
    transform-origin: left;
  }

  @media ${props => props.theme.smallUp} {
    padding: ${rem(50)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(36)} 0;
    padding-bottom: ${rem(25)};
  }
`

const Logo = styled.div`
  width: ${rem(62)};
  position: absolute;
  left: 50%;
  margin-left: ${rem(-31)};
  height: ${rem(20)};
  top: ${rem(-20)};
  background-color: #000;
  border-left: solid 1px #E6E6E6;
  border-right: solid 1px #E6E6E6;
  border-radius: 3px;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: ${rem(43.84)};
    height: ${rem(43.84)};
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: ${rem(8.0797)};
    border-radius: 3px;
  }

  &::before {
    top: ${rem(-21.9203)};
    border-top: solid ${rem(1.4142)} #E6E6E6;
    border-right: solid ${rem(1.4142)} #E6E6E6;
  }

  svg {
    width: ${rem(46)};
    height: ${rem(52)};
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: ${rem(-10)};
    z-index: 2;
  }
`

const TextEl = styled.div`
  svg {
    width: ${rem(250)};
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    @media ${props => props.theme.smallUp} {
      width: ${rem(250)};
    }

    @media ${props => props.theme.smallDown} {
      width: ${rem(200)};
      height: auto;
    }
  }
`



const Header = ( ) => (
  <HeaderEl>

    <Link to="/">
      <Logo>
        <SvgLogo className="svg-logo" />
      </Logo>
    </Link>

    <TextEl>
      <HeaderText className="svg-text" />
    </TextEl>
  </HeaderEl>
)

export default Header
