import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

import TextGlitch from '../text-glitch'


const TextEl = styled.span`
  position: relative;
  white-space: nowrap;

  &::before {
    background: rgb(250, 250, 250);
    position: absolute;
    min-width: 100%;
    left: 0;
    content: attr(data-text);
    animation: textSwap ${props =>props.animationLength}s steps(${props =>props.animationLength}) infinite;
  }
`

let footerTextChanged = false

class FooterText extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      footerMessage: 'Systems ready'
    }

    // if( typeof window != 'undefined' ) {
    //   this.footerTextSwap()
    // }
  }

  footerTextSwap = () => {
    // console.log('footer Text swap')

    // if ( !footerTextChanged ) {
    //    prevFooterText = this.state.footerMessage
    // }

    window.setInterval( () => {

        let targetText = this.props.data[this.props.currentQuestion].footerText.

        footerTextChanged = !footerTextChanged

        let newText = TextGlitch(targetText, 5, footerTextChanged)

        this.setState({
          footerMessage: newText
        })

    }, 1000)
  }

  render() {

    let targetText = 'Systems ready'
    let altText

    if( this.props.currentQuestion-1 >= 0 && this.props.currentQuestion-1 < this.props.data.length) {
      targetText = this.props.data[this.props.currentQuestion-1].footerText.initialText
      altText = TextGlitch(this.props.data[this.props.currentQuestion-1].footerText.altText, 5)
    }

    let animationLength = this.props.data.length-this.props.currentQuestion+1

    return(
      <TextEl className="footer-text" data-text={altText} animationLength={animationLength}>
       {targetText}
      </TextEl>
    )
  }
}

FooterText.propTypes = {
  data: PropTypes.any,
  currentQuestion: PropTypes.any
}

export default FooterText
