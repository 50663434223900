import React from "react"
import PropTypes from "prop-types"

import { StoreContext } from "../../store"

import Intro from "./intro"
import Question from "./question"
import Outro from "./outro/"

class SurveyController extends React.Component {
  static contextType = StoreContext

  constructor(props) {
    super(props)

    this.state = {
      currentQuestion: 0,
      surveyStarted: false,
    }
  }

  surveyStart = () => {
    this.setState({
      surveyStarted: true,
    })

    // find a way to clear it when refreshes
    //navigator.geolocation.getCurrentPosition( (e) => ( e ) )

    this.context.setCurrentQuestion(1)
  }

  questionChange = () => {
    let nextQuestion = this.state.currentQuestion + 1

    //achtung - gotta check why state doesn't update immediately
    this.context.setCurrentQuestion(nextQuestion + 1)

    this.setState({
      currentQuestion: nextQuestion,
    })
  }

  render() {
    if (!this.state.surveyStarted) {
      return <Intro surveyStart={this.surveyStart} />
    } else if (this.props.data.length == this.state.currentQuestion) {
      return <Outro />
    } else {
      return (
        <Question
          totalQuestions={this.props.data.length}
          currentQuestion={this.state.currentQuestion}
          questionChange={this.questionChange}
          {...this.props.data[this.state.currentQuestion]}
        />
      )
    }
  }
}

SurveyController.propTypes = {
  data: PropTypes.array,
}

export default SurveyController
