import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import { rem } from "polished"
import { CSSTransition } from 'react-transition-group'

import Header from './header'
import Footer from './footer'

const ContainerWrapper = styled.div`
  min-height: 100vh;

  @media ${props => props.theme.smallUp} {
    padding: ${rem(60)} 0;
    padding-top: ${rem(100)};
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(30)} 0;
    padding-top: ${rem(80)};
  }
`

const InnerContent = styled.div.attrs({className: 'inner-content'})`
  width: ${rem(620)};
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #e6e6e6;
  border-radius: ${rem(10)};
  background: ${props => props.theme.colorWhite};
`

const Content = styled.div.attrs({className: 'question-content'})`
  position: relative;
  overflow: hidden;
  margin-top: -2px;

  @media ${props => props.theme.smallUp} {
    padding: ${rem(50)} ${rem(80)};
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(30)};
  }  
`

const Container = props => (
  <CSSTransition
    in={true}
    appear={true}
    timeout={2500}
    classNames='initial-screen-fade'
  >
    <ContainerWrapper>
      <InnerContent>
        <Header />

        <Content>
          {props.children}
        </Content>

        <Footer data={props.data} />
      </InnerContent>
    </ContainerWrapper>
  </CSSTransition>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.any
}

export default Container
