import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Link from "../../link"

import SteamIcon from "../../../assets/images/steam2-icon.inline.svg"
// import AppleIcon from "../../../assets/images/apple-icon.inline.svg"
import GogIcon from "../../../assets/images/gog.inline.svg"

const ContentHeading = styled.div.attrs({ className: "footer-heading" })`
  position: relative;
  padding-top: ${rem(20)};
  margin-top: ${rem(30)};

  p {
    font-weight: bold;
  }

  &::before {
    display: block;
    content: "";
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    background: #e6e6e6;
    transform-origin: left;
    position: absolute;
  }
`
const ContentBottom = styled.div.attrs({ className: "links-container" })`
  margin-top: ${rem(20)};
  position: relative;

  @media ${props => props.theme.smallUp} {
    display: flex;
  }

  &::before {
    content: "";
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    background: #e6e6e6;
    transform-origin: left;
    position: absolute;
  }
`

const ContentBottomCol = styled.div.attrs({
  className: "links-container-column",
})`
  position: relative;
  flex: 1;

  &::after {
    content: "";
    left: 100%;
    top: 0;
    width: 1px;
    height: 100%;
    background: #e6e6e6;
    position: absolute;

    @media ${props => props.theme.smallUp} {
      left: 100%;
      top: 0;
      width: 1px;
      height: 100%;
      transform-origin: top;
      transform: scaleY(0);
    }

    @media ${props => props.theme.smallDown} {
      left: 0;
      top: 100%;
      width: 100%;
      height: 1px;
      transform-origin: left;
      transform: scaleX(0);
    }
  }

  &:first-of-type {
    .link {
      svg {
        width: ${rem(18)};
        position: relative;
        top: ${rem(-3)};
      }
    }
  }

  .link {
    display: block;
    text-align: left;
    padding: ${rem(20)};
    line-height: ${rem(24)};

    svg {
      margin-right: ${rem(10)};
      width: ${rem(24)};
      height: ${rem(24)};
    }
  }
`

const OutroLinks = () => (
  <React.Fragment>
    <ContentHeading>
      <p className="footer-text">
        For a Brighter Future – Choose Mosaic
      </p>
    </ContentHeading>

    <ContentBottom>
      {/* <ContentBottomCol>
        <Link
          className="link"
          target="_blank"
          to="https://apps.apple.com/us/app/the-mosaic/id1454782852?ign-itsct=themosaic&ign-itscg=30800"
        >
          <AppleIcon />
          Apple Arcade
        </Link>
      </ContentBottomCol> */}

      <ContentBottomCol>
        <Link
          className="link"
          target="_blank"
          to="https://store.steampowered.com/app/349270/Mosaic/"
        >
          <SteamIcon />
          Steam
        </Link>
      </ContentBottomCol>

      <ContentBottomCol>
        <Link
          className="link"
          target="_blank"
          to="https://www.gog.com/game/mosaic"
        >
          <GogIcon />
          Gog
        </Link>
      </ContentBottomCol>
    </ContentBottom>
  </React.Fragment>
)

export default OutroLinks
