import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import { CSSTransition, TransitionGroup } from "react-transition-group"

import ResultQuotes from "../../../data/result-quotes.json"

import Link from "../../link"

import TwitterIcon from "../../../assets/images/twitter-icon.inline.svg"
import FacebookIcon from "../../../assets/images/fb-icon.inline.svg"

import PosterBelieve from "../../../assets/images/posters/believe.png"
import PosterHappiness from "../../../assets/images/posters/happiness.png"
import PosterLive from "../../../assets/images/posters/live.png"
import PosterLove from "../../../assets/images/posters/love.png"
import PosterMoment from "../../../assets/images/posters/moment.png"
import PosterReplace from "../../../assets/images/posters/replace.png"
import PosterStop from "../../../assets/images/posters/stop.png"

import OutroLinks from "./outro-links.js"

const Button = styled.button.attrs({ className: "outro-button" })`
  background: #42b6df;
  color: #fff;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: ${rem(60)};
  font-size: ${rem(18)};
  font-weight: 700;
  border-radius: 999em;
  text-align: center;

  @media ${props => props.theme.smallUp} {
    padding-left: ${rem(80)};
    padding-right: ${rem(80)};
    margin-top: ${rem(50)};
  }

  @media ${props => props.theme.smallDown} {
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
    margin-top: ${rem(30)};
  }
`

const Quote = styled.p.attrs({ className: "quote-text" })`
  font-weight: 700;
  line-height: 1.2;

  @media ${props => props.theme.smallUp} {
    font-size: ${rem(30)};
    padding-left: ${rem(30)};
    padding-right: ${rem(30)};
  }

  @media ${props => props.theme.smallDown} {
    font-size: ${rem(25)};
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  }
`

const Poster = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: ${rem(-8)};
`

const TextContainer = styled.div.attrs({ className: "outro-text-container" })`
  color: ${props => props.theme.colorBlack};
  letter-spacing: ${rem(-0.04)};
  text-align: justify;
  line-height: 1.7;
  text-align: center;

  @media ${props => props.theme.smallUp} {
    font-size: ${rem(18)};
  }

  @media ${props => props.theme.smallDown} {
    font-size: ${rem(16)};
  }

  p:not(:last-of-type) {
    @media ${props => props.theme.smallUp} {
      margin-bottom: ${rem(35)};
    }

    @media ${props => props.theme.smallDown} {
      margin-bottom: ${rem(15)};
    }
  }

  .highlight {
    color: ${props => props.theme.colorRed};
  }
`

const Content = styled.div`
  @media ${props => props.theme.smallUp} {
    margin: 0 ${rem(-80)} ${rem(-50)} ${rem(-80)};
  }

  @media ${props => props.theme.smallDown} {
    margin: 0 ${rem(-30)} ${rem(-30)} ${rem(-30)};
  }
`

const ShareSection = styled.div.attrs({ className: "share-section" })`
  margin-top: ${rem(30)};

  .heading {
    display: block;
    font-size: ${rem(16)};
  }

  a {
    display: inline-block;
    vertical-align: top;

    svg {
      width: ${rem(20)};
      height: ${rem(20)};
    }

    &:not(:last-of-type) {
      margin-right: ${rem(10)};

      svg {
        width: ${rem(18)};
        height: ${rem(18)};
      }
    }
  }
`

class Outro extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showResult: false,
    }
  }

  posterArray = [PosterBelieve, PosterHappiness, PosterLive, PosterLove, PosterMoment, PosterReplace, PosterStop]

  result = 0
  text = ""
  poster = this.posterArray[0]
  textNumber = 0
  shareableText = ""
  shareableUrl = "https://survey.mosaiccorp.biz/"

  setShareContent = (posterId) => {
    this.shareableText = encodeURI(
      `${this.text.split("<br />").join("")} I've answered ${
        this.result
      }% of answers productively.`
    )
    //this.shareableUrl = encodeURI(`https://survey.mosaiccorp.biz/?=${this.textNumber}`)

    if (typeof window != undefined) {
      this.shareableUrl = encodeURI(window.location.href + `s/${posterId}`)
    }
  }

  rollResult = () => {
    this.setState({
      showResult: true,
    })

    let random = Math.random()
    this.textNumber = Math.floor(4)

    let text = Math.floor(random * this.textNumber)

    this.result = parseInt(random * 100)
    this.text = ResultQuotes[text].text

    random = Math.random()
    let posterId = Math.floor(random * this.posterArray.length)
    this.poster = this.posterArray[posterId]

    this.setShareContent(posterId)
  }

  render() {
    return (
      <TransitionGroup className={`final-screen-fade-outer`}>
        <CSSTransition
          in={true}
          appear={true}
          timeout={0}
          classNames="fade"
          key={this.state.showResult}
        >
          {this.state.showResult ? (
            <Content>
              <TextContainer>
                <Quote dangerouslySetInnerHTML={{ __html: this.text }} />

                <p className="screen-2-text">
                  {this.result}% of answers answered productively
                </p>

                <ShareSection>
                  <span className="heading">Share your results on:</span>

                  <div>
                    <Link
                      target="_blank"
                      to={`https://www.facebook.com/share.php?u=${this.shareableUrl}&quote=${this.shareableText}`}
                    >
                      <FacebookIcon />
                    </Link>

                    <Link
                      target="_blank"
                      to={`https://twitter.com/intent/tweet?text=${this
                        .shareableText +
                        " " +
                        this.shareableUrl + " @krillbite @RawFury %23mosaicgame"}`}
                    >
                      <TwitterIcon />
                    </Link>
                  </div>
                </ShareSection>

                <OutroLinks />

                <Link
                  target="_blank"
                  to="https://store.steampowered.com/app/349270/Mosaic/"
                  className="footer-poster"
                >
                  <Poster src={this.poster} />
                </Link>
              </TextContainer>
            </Content>
          ) : (
            <div>
              <TextContainer>
                <div className="screen-1-text">
                  <p>
                    Thank you. <br />
                    Click to see the results of this evaluation
                  </p>
                </div>
              </TextContainer>

              <Button onClick={this.rollResult}>Results</Button>
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

Outro.propTypes = {
  surveyStart: PropTypes.any,
}

export default Outro
